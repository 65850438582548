import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
import { fromSearchChain } from '@/const/publicData/chain'

// const dicFormatter = (e)=>{
//     let obj_1 = []
//     for(let item of e){
//         if(item){
//             let obj_ = {
//                 label: item.chain,
//                 value: item.chain,
//             }
//             if(item.chainProtocolInfos){
//                 let obj_c = []
//                 for(let items of item.chainProtocolInfos){
//                     obj_c.push({
//                         label: items.protocol,
//                         value: items.protocol
//                     })
//                 }
//                 obj_.children = obj_c
//                 obj_1.push(obj_)
//             }
//         }
//     }
//     return obj_1
// }
export const object = [{
    type: 'select',
    name: t('contra.text2'),
    prop: 'protocolType',
    postData: [
        { label: 'FT', value: 1 },
        { label: 'NFT', value: 2 }],
},
...fromSearchChain,
{
    type: 'select',
    name: t('contra.text11'),
    prop: 'status',
    postData: [{ label: t('contra.text13'), value: 1 },{ label: t('contra.text12'), value: 0 }]
},{
    type: 'datetime',
    name: t('contra.text9'),
    prop: 'createTime'
}]