<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList' />
        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange">
            <template #menu-left>
                <el-button v-if="roleManager_btn_add" class="filter-item"
                    type="primary"   @click="openView(0)">{{$t('button.add')}} </el-button>
            </template>
            <template #chain_protocol='scope'>
                <div>{{scope.row.chain}} / {{scope.row.protocol}}</div>
            </template>
            <template #status ="scope">
                <el-switch v-model="scope.row.status"
                :inactive-value='0' :active-value='1' @change='enableFun(scope.row)'/>
            </template>   
            <template #caozuo="scope">
                <!-- <el-button v-if="roleManager_btn_update" text type="primary"
                    size="small"    @click="enableFun(scope.row)">{{$t('button.open_')}}/{{$t('button.closeQ')}} </el-button> -->
                <el-button v-if="roleManager_btn_edit" text type="primary"
                    size="small"    @click="openView(1,scope.row)">{{$t('button.update')}} </el-button>
            </template>
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #updateTime="scope">
                {{turnTimeFun(scope.row.updateTime)}}
            </template>
        </avue-crud>
        <el-dialog v-model="dialogFormVisible" :title='isStyleText[isStyle]' width="600">
            <bindView ref='bindView_' @successFun='successFun'/>
            <template #footer>
                <el-button  @click="dialogFormVisible = false">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary"   @click="completeFun">{{$t('button.tijiao')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,getCurrentInstance,nextTick } from 'vue'
    import { tableOption1 } from "@/const/crud/coin/contract";
    import { ElNotification } from 'element-plus'
    import fromSearch from '@/components/fromSearch.vue'
    import { object } from '@/const/from/coin/contract'
    const object_ = ref(object);
    import { turnTimeFun } from '@/utils/util.js'

    import mixins from '@/views/mixins/page'
    const { $t } = getCurrentInstance().proxy;
    let pageObj = mixins(['getContractList'])
    const {page,dialogFormVisible,listLoading,sizeChange,currentChange,getList,successFun} = pageObj
    const isStyle = ref(0)
    const isStyleText = [$t("button.add"),$t("button.update")]
    import bindView from './bind.vue'
    const bindView_ = ref(null)
    
    const roleManager_btn_add = ref(true)
    const tableOption = ref({});tableOption.value = tableOption1()
    // const roleManager_btn_update = ref(true)
    const roleManager_btn_edit = ref(true)
    // const roleManager_btn_del = ref(true)

    const completeFun = ()=>{
        bindView_.value.completeFun()
    }
    const openView = (num,parm)=>{
        isStyle.value = num
        dialogFormVisible.value = true
        nextTick(()=>{
            bindView_.value.init_(num,parm)
        })
    }
    const enableFun = (e)=>{
        if(e.templateId){
            let s_ = e.status === 0?0:1
            listLoading.value = true
            Api_.updateContractStatus({
                templateId: e.templateId,
                status: s_
            }).then(res=>{
                listLoading.value = false
                if(res.code === 0){
                    ElNotification({
                        message: e.status === 1?$t('alert.succOpen'):$t('alert.succOff'),
                        type: 'success',
                    })
                    getList()
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        }
    }
    getList(1)
</script>
