import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const dicFormatter = (e)=>{
    let obj_1 = []
    for(let item of e){
        if(item){
            let obj_ = {
                label: item.chain,
                value: item.chain,
            }
            if(item.chainProtocolInfos.length > 0){
                let obj_c = []
                for(let items of item.chainProtocolInfos){
                    obj_c.push({
                        label: items.protocol,
                        value: items.protocol
                    })
                }
                obj_.children = obj_c
                obj_1.push(obj_)
            }
        }
    }
    return obj_1
}
export const tableOption1 = ()=>{
    return {
        border: true,
        stripe: true,
        menuAlign: 'center',
        searchMenuSpan: 6,
        height: 'auto',
        align: 'center',
        viewBtn: false,
        columnBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        refreshBtn: false,
        menuWidth: 100,
        menu:false,
        column: [{
            width: 100,
            label: t('chain.text2'),
            prop: 'chain',
        }, {
            width: 100,
            label: t('agreement.text3'),
            prop: 'protocol',
        }, {
            width: 170,
            label: t('contra.text2'),
            prop: 'protocolType',
            type: 'radio',
            dicData: [{
              label: t('contra.name2_1'),
              value: 1
            }, {
              label: t('contra.name2_2'),
              value: 2
            }]
        }, {
            width: 200,
            label: t('contra.text4'),
            prop: 'contractName',
        }, {
            width: 160,
            label: t('contra.text14'),
            prop: 'contractClassName',
        },{
            width: 130,
            label: t('contra.text6'),
            prop: 'contractType',
            type: 'radio',
            dicData: [{
              label: t('contra.text7'),
              value: 1
            }]
        }, {
            width: 130,
            label: 'Gas Limit',
            prop: 'deployGasLimit',
        },  {
            width: 140,
            label: t('contra.name1'),
            prop: 'mintType',
            type: 'radio',
            dicData: [{
              label:t('contra.name1_1'),
              value: 1
            },{
                label:t('contra.name1_2'),
                value: 2
              }]
        },{
            width: 130,
            label: t('contra.name2'),
            prop: 'supplyType',
        },
        {
            width: 160,
            label: t('contra.text9'),
            prop: 'createTime',
        }, 
        {
            width: 160,
            label: t('chain.text10'),
            prop: 'updateTime',
        },{
            width: 150,
            label: t('contra.text10'),
            prop: 'updateUser',
        },{
            width: 130,
            label: t('contra.text11'),
            prop: 'status',
            fixed:'right',
        },{
            width: 150,
            label: t("button.operation"),
            prop: 'caozuo',
            fixed:'right',
          }]
    }
}